module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/assets/images/panda.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"dangerouslySetAllPagesToNoIndex":true,"openGraph":{"type":"website","locale":"en_US","url":"https://sebabytes.com","site_name":"sebabytes"},"twitter":{"handle":"@sebabytes","site":"@sebabytes","cardType":"summary_large_image"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
